<template lang="pug">
	.select
		select(:value="value", @input="$emit('input', $event.target.value)")
			option(value="", disabled) {{ contents.label }}
			option(v-for="item in contents.options", :value="item.value") {{ item.label }}
</template>

<script>
import { props } from '@/mixins/component'

export default {
	name: "component-select",
	props: {
		...props,
		value: {
			type: String,
			default: ``
		}
	},
}
</script>

<style lang="stylus" scoped src="./Select.styl"></style>
